<template>
  <v-col cols="12" lg="7">
    <v-data-table
      :headers="headers"
      :items="localPayments"
      :loading="isLoading"
      :items-per-page="15"
      hide-default-footer
      class="table elevation-1 mt-3  white--text"
      dark
    >
      <template v-slot:top>
        <section class="head d-flex justify-center pa-3 white--text">
          <h3>Payment List</h3>
        </section>
      </template>
    </v-data-table>

    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="6"
          @input="fetchPayments()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      localPayments: [],
      page: 1,
      lastPage: null,
      total: 0,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          class: "white--text",
        },
        { text: "Amount", value: "amount", class: "white--text" },
        { text: "Description", value: "description", class: "white--text" },
        { text: "Status", value: "status", class: "white--text" },
      ],
    };
  },
  created() {
    this.fetchPayments();
  },
  methods: {
    ...mapActions(["getPaymentList"]),
    fetchPayments() {
      this.isLoading = true;
      this.getPaymentList(this.page)
        .then((result) => {
          this.localPayments = result.data;
          this.total = result.meta.total;
          this.lastPage = result.meta.last_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table{
  background: var(--base-color) !important;
  .head {
    background: #212121;
    border:1px solid white;
    border-bottom: none;
    border-radius: 4px;
    margin-bottom: -2px;
  }
  ::v-deep.v-data-table__wrapper {
    border: 1px solid rgb(255, 255, 255) !important;
  }
}
// pagination
.table-paginate-box {
  width: 100%;
  padding: 13px 60px;
  margin-top: -3px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 0px 0px 4px 4px;
  border-top: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .table-pagination-total {
    background: var(--base-color);
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    border-radius: 25px;
    padding: 8px 28px;
    text-align: right;
    letter-spacing: 0;
    font-weight: 400;
    box-shadow: 0px 2px 5px #0000009a;
  }
  .v-pagination {
    background: unset;
    justify-content: flex-end;
    width: auto;
  }
  @media only screen and(max-width:700px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  ::v-deep .v-pagination__item {
    &.v-pagination__item--active {
      background-color: var(--base-color) !important;
      color: var(--secondary-color);
    }
  }
}
</style>

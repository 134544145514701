<template>
  <section class="callback-payment routes d-flex justify-center align-center">
    <div
      class="card d-flex flex-column align-center"
      :class="verified ? 'border-success' : 'border-error'"
    >
    <v-img src="../../assets/images/email-verify-logo.png" width="60%" contain></v-img>
    <h2 class="
    mb-9">Email Verification</h2>
      <h2
        class="white--text mb-5"
        :class="verified ? 'green--text' : 'error--text'"
      >
        <template v-if="!isLoading">
          {{
            verified
              ? "Your Email Verified Successfully!"
              : "Email Not Verified"
          }}
        </template>
        <v-icon large right color="success" v-if="verified"
          >mdi-check-circle</v-icon
        ><v-icon large color="error" right v-else-if="!verified && !isLoading"
          >mdi-close-circle</v-icon
        >
      </h2>
      <v-btn
        @click="$router.replace('/profile')"
        color="white"
        text
        :loading="isLoading"
        >Back To Page</v-btn
      >
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      verified: false,
      isLoading: false,
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    ...mapActions(["getEmailVerify"]),
    async checkQuery() {
      let hash = this.$route.query.hash;
      if (hash) {
        this.isLoading = true;
        await this.getEmailVerify(hash).then((res) => {
          if (res) {
            this.isLoading = false;
            this.verified = true;
          } else {
            this.isLoading = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.callback-payment {
  background: url("../../assets/images/email-vetify-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh !important;
  .card {
    width: 95%;
    max-width: 500px;
    border-radius: 15px;
    padding:30px 20px 50px 20px;
    margin-top: 40px;
    background: var(--base-color);
    opacity: 0.9;
    box-shadow: 10px 25px 12px 2px rgba(11, 11, 11, 0.856);
    &.border-success {
      // border: 4px solid green;
    }
    &.border-error {
      // border: 4px solid red;
    }
  }
}
</style>

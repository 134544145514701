<template>
  <v-col cols="12" lg="5" class="charge-account">
    <v-card outlined dark>
      <v-card-title class="grey darken-4">
        <div class="d-flex align-center justify-space-between w-100">
          <h4>Charge Account</h4>

          <v-skeleton-loader
            v-if="!coins[0].coin_id"
            type=" divider,divider,divider,divider,divider,divider,divider,divider"
            width="40%"
          ></v-skeleton-loader>

          <section v-else class="balance d-sm-flex">
            <h5 class="grey--text mr-2">
              {{ coins[0].coin_name }} :
              <span class="white--text">{{
                coins[0].amount ? coins[0].amount : 0
              }}</span>
            </h5>
            <h5 class="grey--text">
              {{ coins[1].coin_name }} :
              <span class="white--text">{{
                coins[1].amount ? coins[1].amount : 0
              }}</span>
            </h5>
          </section>
        </div>
      </v-card-title>

      <v-card-text>
        <p class="mb-4">
          You need to charge your wallet to be able to buy payable features. All
          payments will use your wallet balance to pay.
        </p>
        <p class="m-0">
          For now crypto currency payment is available and other payment methods
          will shown here if available.
        </p>

        <v-divider class="my-3" />

        <v-form ref="payment">
          <div class="form-group">
            <v-row no-gutters>
              <v-col cols="12" sm="6" class=""
                ><label>Amount</label>
                <v-text-field
                  type="email"
                  :rules="rules.amount_rules"
                  v-model="amount"
                  step="0.000001"
                  min="0.000001"
                  outlined
                  dense
                  placeholder="12000"
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6" class="my-auto">
                <label class="">Coin</label>
                <v-select
                  v-model="selectedCoin"
                  :items="coins"
                  :rules="rules.select"
                  item-text="coin_name"
                  item-value="coin_name"
                  label="Coins List"
                  :menu-props="{ bottom: true, offsetY: true }"
                  solo
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex">
            <v-btn
              class="charge-btn"
              :loading="loading"
              :disabled="loading"
              @click="submitPayment"
            >
              Charge
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      amount: "",
      loading: false,
      valid: false,
      coins: [
        {
          coin_name: "",
          coin_id: "",
          amount: 0,
        },
        {
          coin_name: "",
          coin_id: "",
          amount: 0,
        },
      ],
      selectedCoin: "",
      coin1balance: 0,
      coin2balance: 0,
    };
  },
  computed: {
    ...mapGetters(["rules", "user"]),
  },
  created() {
    this.fetchUserCoins();
  },
  methods: {
    ...mapActions(["createPayment", "getUserCoins"]),
    fetchUserCoins() {
      this.getUserCoins().then((res) => {
        this.coins = res.result;
      });
    },
    submitPayment() {
      if (this.$refs.payment.validate()) {
        let info = {
          amount: this.amount,
          coin: this.selectedCoin,
        };
        this.createPayment(info).then((res) => {
          location.replace(res.result.url);
          this.$refs.payment.reset();
        });
      }
    },
  },
  watch: {
    selectedCoin() {
      console.log(this.selectedCoin);
    },
  },
};
</script>
<style lang="scss" scoped>
.charge-account {
  margin: 12px 0 0 0;
  .v-select {
    ::v-deep .v-input__slot {
      background: var(--base-color);
    }
    ::v-deep .v-input__control {
      min-height: none;
    }
  }
  ::v-deep.v-text-field {
    max-width: 75% !important;
  }
  .charge-btn {
    background: var(--third-color);
    color: black;
    font-weight: bolder;
    width: 100%;

    margin-left: auto;
    @media only screen and(max-width: 1260px) {
      width: 20%;
      min-width: 120px;
    }
  }
}
</style>

<template>
  <section class="profile routes d-flex flex-column aling-center">
    <div class="head">
      <h2 class="text-center">profile</h2>
    </div>
    <div class="line"></div>
    <div class="profile-card mx-auto">
      <v-row>
        <v-col>
          <section class="">
            <div class="loading" v-if="isLoading">
              <loading-spinner></loading-spinner>
            </div>
            <section class="content pa-4" v-else>
              <div class="mb-5">
                <label class="">Wallet ID:</label>
                <p class="wallet">
                  {{ wallet_address }}
                  <i class="icon fa-solid fa-copy" @click="copyWallet">
                    <span class="tooltip" v-if="isWalletCopied"
                      >Wallet address Copied!</span
                    ></i
                  >
                </p>
              </div>
              <section class="email">
                <label for="">Email</label>
                <section class="d-flex justify-space-between">
                  <v-text-field
                    v-model="email"
                    type="email"
                    :rules="rules.email_rules"
                    outlined
                    dense
                    clearabl
                    dark
                  ></v-text-field>
                  <v-btn
                    height="40"
                    :loading="verifyLoading"
                    @click="emailVerify"
                    class="btn regular"
                    :class="emailVerified ? 'email-verified' : ''"
                    >{{ emailVerified ? "Verified" : "Verify" }}</v-btn
                  >
                </section>
              </section>
              <section class="password">
                <label for="">Password</label>
                <div class="w-100 d-flex justify-space-between">
                  <v-text-field
                    v-model="password"
                    type=""
                    outlined
                    placeholder="A54ssdw34ND"
                    dense
                    dark
                    clearable
                  ></v-text-field>
                  <v-btn class="btn regular" height="40">Update</v-btn>
                </div>
              </section>
              <section class="referral-code">
                <label for="">Referral Code</label>
                <section class="d-flex justify-space-between">
                  <v-text-field
                    type=""
                    outlined
                    dense
                    dark
                    clearable
                    placeholder="559127425A"
                  ></v-text-field>
                  <v-btn class="btn regular" height="40">Copy</v-btn>
                </section>
              </section>
              <section class="tele-2fa d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <label class="">Telegram:</label>
                  <v-btn class="btn"
                    >Not Connected
                    <i class="fa-brands fa-telegram" style="font-size: 22px"></i
                  ></v-btn>
                </div>
                <div class="d-flex flex-column">
                  <label class="">2Fa Authentication:</label>
                  <v-btn class="btn"
                    >Not Enabled
                    <i
                      class="fa-solid fa-circle-exclamation"
                      style="font-size: 22px"
                    ></i
                  ></v-btn>
                </div>
              </section>
              <v-btn class="save-btn">Save</v-btn>
            </section>
          </section>
        </v-col>
      </v-row>
    </div>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
      color="green accent-4 "
      elevation="100"
      absolute
      centerd
      shaped
      top
      transition="slide-y-transition"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      password: "",
      emailVerified: false,
      isLoading: false,
      verifyLoading:false,
      message: "",
      snackbar: false,
      isWalletCopied: false,
    };
  },
  computed: {
    ...mapGetters(["wallet_address", "rules"]),
  },
  created() {
    this.gerUserInfo();
  },
  methods: {
    ...mapActions(["getProfileInfo", "sendEmailVerify"]),
    gerUserInfo() {
      this.isLoading = true;
      this.getProfileInfo().then((result) => {
        console.log(result);
        this.email = result.result.email;
        if (result.result.email_verified_at) {
          this.emailVerified = true;
        }
        this.isLoading = false;
      });
    },
    emailVerify() {
      if (!this.emailVerified) {
        this.verifyLoading = true;
        this.sendEmailVerify().then((result) => {
          console.log(result);
          this.verifyLoading = false;
          this.snackbar = true;
          this.message = result.message;
        });
      }
    },
    copyWallet() {
      navigator.clipboard.writeText(this.wallet_address);
      this.isWalletCopied = true;
      setTimeout(() => {
        this.isWalletCopied = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  color: white;
  .head {
  }
  .line {
    max-width: 700px !important;
    margin: 5px auto;
  }
  .profile-card {
    width: 90%;
    max-width: 500px;
    border-radius: 5px;
    .wallet {
      border: 2px solid var(--third-color);
      color: var(--third-color);
      padding: 9px;
      border-radius: 5px;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      .icon {
        font-size: 18px;
        cursor: pointer;
        position: relative;
        .tooltip {
          position: absolute;
          background: var(--third-color);
          color: var(--base-color);
          font-size: 12px;
          top: -45px;
          left: 0;
          transform: translateX(-50%);
          white-space: nowrap;
          padding: 8px;
          letter-spacing: 1px;
          border-radius: 2px;
          &::after {
            content: "";
            position: absolute;
            display: flex;
            left: 50%;
            bottom: -10px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid var(--third-color);
          }
        }
      }
    }
    .tele-2fa {
      div {
        width: 45%;
      }
      .btn {
        display: flex;
        justify-content: space-around;
      }
    }
    label {
      margin-left: 7px;
    }
    .loading {
      height: 256px;
    }
    .btn {
      background: var(--secondary-color);
      font-weight: bolder;
      color: var(--base-color);
      &.regular {
        width: 20%;
      }
      &.email-verified {
        background: var(--base-color);
        color: var(--secondary-color);
        cursor: not-allowed;
      }
    }
    .save-btn {
      width: 100%;
      font-weight: bolder;
      margin-top: 40px;
      background: var(--third-color);
    }
  }
}
::v-deep.v-text-field {
  width: 100%;
  max-width: 75%;
  input {
    &::placeholder {
      color: var(--light-blue);
      filter: brightness(0.8);
    }
  }
  fieldset {
    border: 2px solid var(--light-blue) !important;
  }
}
</style>
